<template>
    <div class="exchange-index-box">
        <HeadBox 
            @backIndex="backIndex"
            @chackDetail="chackDetail"
        ></HeadBox>
        <div class="exchange-select-list-box" v-if="gameCoinExchangeList.length>0">
            <div class="title">
                1个游戏币={{exchangeRatio | changMoney}}元
            </div>
            <div class="exchange-select-list">
               <div class="exchange-select-item-box" v-for="(item, index) in gameCoinExchangeList" :key="index">
                   <div class="exchange-select-item" :class="index===choiceCoinCount?'exchange-select-item-s':''" @click="choiceCoinExchangeCount(index)">
                       <div class="coin-count">
                           <img src="@/assets/index/exchange/game_coin_icon.svg">
                           <span>&nbsp;{{item.reward_params.nums}}</span>
                       </div>
                       <div class="tag-text" v-if="item.reward_params.times&&item.reward_params.times>0">
                           限兑换{{item.reward_params.times}}次
                       </div>
                       <img src="@/assets/index/exchange/index_selected.svg" class="select-icon" v-if="index===choiceCoinCount">
                       <!-- 兑换限制 -->
                       <!-- 后台不设置 -->
                       <div class="premise-text" v-if="!item.reward_params.days&&!item.reward_params.level&&!item.reward_params.money"></div>
                       <!-- 天数限制 -->
                       <div class="premise-text" v-if="item.reward_params.days&&!item.reward_params.level&&!item.reward_params.money">
                           创角{{item.reward_params.days}}天内
                       </div>
                       <!-- 等级限制 -->
                       <div class="premise-text" v-if="!item.reward_params.days&&item.reward_params.level&&!item.reward_params.money">
                           达到{{item.reward_params.level}}级
                       </div>
                       <!-- 充值限制 -->
                       <div class="premise-text" v-if="!item.reward_params.days&&!item.reward_params.level&&item.reward_params.money">
                           累计充值{{item.reward_params.money | changMoney}}元
                       </div>
                       <!-- 天数&等级 -->
                       <div class="premise-text" v-if="item.reward_params.days&&item.reward_params.level&&!item.reward_params.money">
                           {{item.reward_params.days}}天内达到{{item.reward_params.level}}级
                       </div>
                       <!-- 天数&充值 -->
                       <div class="premise-text" v-if="item.reward_params.days&&!item.reward_params.level&&item.reward_params.money">
                           {{item.reward_params.days}}天内累计充值{{item.reward_params.money | changMoney}}元
                       </div>
                       <!-- 等级&充值 -->
                       <div class="premise-text" v-if="!item.reward_params.days&&item.reward_params.level&&item.reward_params.money">
                           累计充值{{item.reward_params.money | changMoney}}元并且达到{{item.reward_params.level}}级
                       </div>
                       <!-- 天数&等级&充值 -->
                       <div class="premise-text" v-if="item.reward_params.days&&item.reward_params.level&&item.reward_params.money">
                           {{item.reward_params.days}}天内累计充值{{item.reward_params.money | changMoney}}元并且达到{{item.reward_params.level}}级
                       </div>
                   </div>
               </div>
            </div>
            <div class="exchange-btn" @click="exchangeGameCoin">
                兑换游戏币
            </div>
            <div class="rules-text-box">
                <div class="rules-title">
                    规则说明：
                </div>
                <div class="rules">
                    {{exchangeTips}}
                </div>
            </div>
        </div>
        <div class="blank" v-else>
            <img src="@/assets/index/exchange/blank_coin_list.svg" alt="">
            <div>
                暂时无法兑换~
            </div>
        </div>
    </div>
</template>

<script>
    import HeadBox from '@/components/Index/Exchange/Vertical/Index/HeadBox.vue'
    export default {
        components: {
            HeadBox
        },
        props: {
           gameCoinExchangeList:Array,
           choiceCoinCount:Number,
           exchangeTips:String,
           exchangeRatio:Number
        },
        data() {
            return {
                
            }
        },
        methods: {
           choiceCoinExchangeCount(i) {
               this.$emit('choiceCoinExchangeCount', i)
           },
           exchangeGameCoin() {
               this.$emit('exchangeGameCoin')
           },
           backIndex() {
               this.$emit('backIndex')
           },
           chackDetail() {
               this.$emit('chackDetail')
           }
        }
    }
</script>

<style scoped>
    .exchange-select-list-box {
        padding: 48px 15px 23px 15px;
    }
    .title {
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
    }
    .exchange-select-list {
        padding-top: 2px;
        padding-bottom: 23px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .exchange-select-item-box {
        padding-top: 14px;
    }
    .exchange-select-item {
        width: 144px;
        height: 86px;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        border: 1px solid #E4E4E4;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 19px;
    }
    .exchange-select-item-s {
        background: rgba(19,198,206,0.03);
        border: 1px solid #13C6CE;
    }
    .coin-count {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .coin-count>img {
        width: 16px;
    }
    .coin-count>span {
        font-size: 20px;
        font-family: DIN-Bold, DIN;
        font-weight: bold;
        color: #333333;
    }
    .premise-text {
        padding-top: 4px;
        text-align: center;
        font-size: 11px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #B57D1D;
    }
    .tag-text {
        position: absolute;
        top: -10px;
        left: 0;
        padding-left: 6px;
        padding-right: 8px;
        height: 21px;
        background: linear-gradient(270deg, #FC6E22 0%, #FF211C 100%);
        border-radius: 9px 9px 9px 0px;
        display: flex;
        align-items: center;
        font-size: 11px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        
    }
    .select-icon {
        width: 29px;
        position: absolute;
        bottom: 0;
        right: 0;
    }
    .exchange-btn {
        width:100%;
        height: 42px;
        background: #13C6CE;
        border-radius: 25px 25px 25px 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
    }
    .rules-text-box {
        padding-top: 25px;
    }
    .rules-title {
        font-size: 16px;
        font-weight: 500;
    }
    .rules {
        font-size: 13px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        white-space: pre-wrap;
    }
    .rules>p {
        padding-top: 4px;
    }
    .blank {
        padding: 48px 15px 0 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .blank>img {
        width: 100%;
    }
    .blank>div {
        font-size: 13px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        padding-top: 20px;
    }
</style>