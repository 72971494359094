<template>
    <div class="head-box">
        <img src="@/assets/index/exchange/index_back_left.svg" class="index-back-left">
        <img src="@/assets/index/exchange/index_back_right.svg" class="index-back-right">
        <div class="title-box">
            <img src="@/assets/index/exchange/icon_back.svg" class="back_icon" @click="backIndex">
            <div class="title">
                兑换
            </div>
            <div class="title-sc" @click="chackDetail">
                明细
            </div>
        </div>
        <div class="overview-data-box">
            <div class="balance-count-box">
                <div class="balance-title-box">
                    <img src="@/assets/index/exchange/balance_icon.svg" class="title-icon">
                    <span>&nbsp;余额 (元)</span>
                </div>
                <div class="balance">
                    {{$store.state.gameRewardMoney}}
                </div>
            </div>
            <div class="line"></div>
            <div class="game-coin-count-box">
                <div class="game-coin-title-box">
                    <img src="@/assets/index/exchange/game_coin_icon.svg" class="title-icon">
                    <span>&nbsp;游戏币 (个)</span>
                </div>
                <div class="game-coin">
                    {{$store.state.gameCoin | changMoney}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
         methods:{
             backIndex() {
                 this.$emit('backIndex')
             },
             chackDetail() {
                 this.$emit('chackDetail')
             }
         }
    }
</script>

<style scoped>
    .head-box {
        width: 100%;
        padding: 9px;
        position: relative;
        height: 104px;
        background: linear-gradient(90deg, #DBF5E9 0%, #C2E7F8 100%);
    }
    .title-box {
        padding: 4.375px;
        padding-bottom: 7px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        z-index: 10;
    }
    .back_icon {
        width: 15px;
    }
    .title {
        flex: 1;
        text-align: center;
        font-size: 11px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
    }
    .title-sc {
        font-size: 8.75px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        height: 8.75px;
        line-height: 12.5px;
    }
    .index-back-left {
        position: absolute;
        top: 0;
        left: 0;
    }
    .index-back-right {
        position: absolute;
        top: 0;
        right: 0;
    }
    .overview-data-box {
        width: 188.75px;
        padding: 19px 0 9px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #FFFFFF;
        box-shadow: 0px 2px 3.75px 1px #E5EBEB;
        border-radius: 8.75px;
        position: absolute;
        z-index: 10;
        left: 50%;
        transform: translateX(-50%);
        bottom: -19.3px;
    }
    .line {
        height: 62px;
        border-right: 1px solid #E4E4E4;
    }
    .balance-count-box,
    .game-coin-count-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        height: 55px;
        width: 94px;
        padding-bottom: 8px;
    }
    .balance-title-box,
    .game-coin-title-box {
        width: 69px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .balance-title-box>img {
        width: 11.25px;
    }
    .game-coin-title-box>img {
        width: 10px;
    }
    .balance-title-box>span,
    .game-coin-title-box>span{
        font-size: 8.75px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
    }
    .balance,
    .game-coin {
        font-size: 17.5px;
        font-family: DIN Alternate-Bold, DIN Alternate;
        font-weight: bold;
        color: #13C6CE;
    }
</style>