<template>
    <div class="bind-phone-window">
        <div class="title-box">
            <div class="close-icon"></div>
            <div class="title">
                请先绑定手机号
            </div>
            <img src="@/assets/index/exchange/close_icon.svg" class="close-icon" @click="closeWindow">
        </div>
        <div class="form-box">
            <div class="input-box-phone">
                <input v-model="formData.phone" 
                type="number" 
                onkeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))" 
                @input="formData.phone=formData.phone.substr(0,11)"
                placeholder="请输入中国大陆11位手机号">
            </div>
            <div class="input-box">
                <div class="input-code">
                    <input
                    v-model="formData.code" 
                    type="number" 
                    onkeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
                    @input="formData.code=formData.code.substr(0,6)"
                    placeholder="请输入验证码">
                </div>
                <div class="get-code-btn-box">
                    <div class="get-code-btn" v-if="countdown<1" @click="getPhoneCode">
                        获取验证码
                    </div>
                    <div class="get-code-countdown" v-else>
                        重新发送{{'('+ countdown+ 's)'}}
                    </div>
                </div>
            </div>
        </div>
        <div class="sub-btn" @click="bindPhone" :class="formData.phone!==null&&formData.code!==null&&formData.phone.length===11&& formData.code.length===6?'sub-btn-active':''">
            确定并绑定
        </div>
    </div>
</template>

<script>
    export default {
        props:{
            countdown:Number
        },
        data() {
            return {
                formData:{
                    phone:null,
                    code:null
                }
            }
        },
        methods:{
            closeWindow() {
                this.$emit('closeWindow')
            },
            getPhoneCode() {
                this.$emit('getPhoneCode', this.formData)
            },
            bindPhone() {
                this.$emit('bindPhone', this.formData)
            }
        }
    }
</script>

<style scoped>
    .bind-phone-window {
        width: 175px;
       padding: 13.75px 14px;
        background: #FFFFFF;
        border-radius: 8.75px;
    }
    .title-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .title {
        flex: 1;
        text-align: center;
        font-size: 11.25px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
    }
    .close-icon {
        width: 7px;
    }
    .form-box {
        padding-top: 8.75px;
        padding-bottom: 9px;
    }
    .input-box,
    .input-box-phone {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #F4F4F4;
        border-radius: 3.75px;
        margin-top: 5px;
        padding: 0 7px;
    }
    .input-code {
        flex: 1;
    }
    .input-code>input,
    .input-box-phone>input {
        border: none;
        width: 100%;
        height: 24px;
        background: #F4F4F4;
        font-size: 8.75px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
    }
    .get-code-btn-box {
        padding-left: 3.125px;
        
    }
    .get-code-btn {
        font-size: 8.75px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #13C6CE;
    }
    .get-code-countdown {
        font-size: 8.75px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
    }
    .sub-btn {
        width: 100%;
        font-size: 10px;
        font-family: PingFang SC-Regular, PingFang SC;
        height: 23.75px;
        background: rgba(19,198,206,0.5);
        transition: 0.5s;
        border-radius: 12px;
        font-weight: 400;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .sub-btn-active {
        background: rgba(19,198,206,1);
        transition: 0.5s;
    }
</style>