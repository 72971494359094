<template>
    <div class="bind-phone-box">
        <transition name="bindPhone" appear>
            <Horizontal 
            :countdown="countdown"
            @closeWindow="closeWindow"
            @getPhoneCode="getPhoneCode"
            @bindPhone="bindPhone"
                v-if="$store.getters.currentPageVersion === 'Horizontal'">
            </Horizontal>
            
            <Vertical 
            :countdown="countdown"
            @closeWindow="closeWindow"
            @getPhoneCode="getPhoneCode"
            @bindPhone="bindPhone"
            v-else></Vertical>
        </transition>
    </div>
</template>

<script>
    import Horizontal from '@/components/Index/Exchange/BindPhoneDialog/Horizontal.vue'
    import Vertical from '@/components/Index/Exchange/BindPhoneDialog/Vertical.vue'
    export default {
        components: {
            Horizontal,
            Vertical
        },
        props: {
            countdown:Number
        },
        data() {
            return {}
        },
        methods: {
            closeWindow() {
                this.$emit('closeWindow')
            },
            getPhoneCode(formData) {
                this.$emit('getPhoneCode', formData)
            },
            bindPhone(formData) {
                this.$emit('bindPhone', formData)
            }
        }
    }
</script>

<style scoped>
    .bindPhone-enter,
    .bindPhone-leave-to,
    .bindPhone-enter {
        opacity: 0;
        transform: scale(0);
    }

    .bindPhone-enter-to,
    .bindPhone-leave {
        opacity: 1;
        transform: scale(1);
    }

    .bindPhone-enter-active,
    .bindPhone-leave-active {
        transition: 0.3s;
    }

    .bind-phone-box {
        height: 100vh;
        width: 100vw;
        background-color: rgba(0, 0, 0, 0.3);
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;
    }
</style>