<template>
    <div style="padding: 0; overflow-y: scroll;">
        <Horizontal ref="Horizontal"
        :gameCoinExchangeList="gameCoinExchangeList"
        :choiceCoinCount="choiceCoinCount"
        :exchangeTips="exchangeTips"
        :exchangeRatio="exchangeRatio"
        @choiceCoinExchangeCount="choiceCoinExchangeCount"
        @exchangeGameCoin="exchangeGameCoin"
        @backIndex="backIndex"
        @chackDetail="chackDetail"
         v-if="$store.getters.currentPageVersion === 'Horizontal'"></Horizontal>

        <Vertical 
        :gameCoinExchangeList="gameCoinExchangeList"
        :choiceCoinCount="choiceCoinCount"
        :exchangeTips="exchangeTips"
        :exchangeRatio="exchangeRatio"
        @choiceCoinExchangeCount="choiceCoinExchangeCount"
        @exchangeGameCoin="exchangeGameCoin"
        @backIndex="backIndex"
        @chackDetail="chackDetail"
        v-else></Vertical>

    <!-- 绑定手机弹窗 -->
    <BindPhoneDialog 
    v-if="isShowBindPhoneDialog"
    :countdown="countdown" 
    @closeWindow="closeBindPhoneDialog"
    @getPhoneCode="getPhoneCode"
    @bindPhone="bindPhone"
    ></BindPhoneDialog>
    </div>
</template>

<script>
    import Horizontal from '@/components/Index/Exchange/Horizontal/Index.vue'
    import Vertical from '@/components/Index/Exchange/Vertical/Index.vue'
    
    import BindPhoneDialog from '@/components/Index/Exchange/BindPhoneDialog/Index.vue'
    
    export default {
        components: {
            Horizontal,
            Vertical,
            BindPhoneDialog
        },
        data() {
            return {
                gameCoinExchangeList:[],
                exchangeTips:'',//兑换规则说明
                exchangeRatio:null,//兑换比例说明
                choiceCoinCount:0,
                isShowBindPhoneDialog:false,
                countdownTimer:null,
                countdown:0,//绑定手机窗口验证码倒计时
                
            }
        },
        created() {
            if(this.$store.state.roleId!=='--'&&this.$store.state.gameRewardMoney === '--') {
                this.$router.replace({
                   path: '/',
                   query:{
                       redirect:'/index/exchangeIndex'
                   }
                })
                return
            }
            this.getExchangeGameCoinList()
        },
        methods: {
            //获取游戏币列表
            getExchangeGameCoinList() {
                let requestData = {
                    role_id: this.$store.state.roleId,
                    token: this.$store.state.token
                }
                this.$api.exchange.getExchangeGameCoinList(requestData).then(res=> {
                    this.gameCoinExchangeList = res.data.data.list;
                    this.exchangeTips = res.data.data.rule_text;
                    this.exchangeRatio = res.data.data.ratio;
                    if(res.data.data.hasOwnProperty('game_coin')) {
                        this.$store.commit('getGameCoin', res.data.data.game_coin);
                    }
                    if(res.data.data.hasOwnProperty('game_reward_money')) {
                        this.$store.commit('getGameRewardMoney', (Number(res.data.data.game_reward_money)/ 100).toFixed(2));
                    }
                    this.choiceCoinCount = 0;
                })
            },
            //点击兑换游戏币
            exchangeGameCoin() {
                // setTimeout(()=> {
                //     this.getExchangeGameCoinList()
                // },1500)
                // return
                if(this.$store.state.bindPhone===null) {
                    this.isShowBindPhoneDialog = true
                    return
                }
                let requestData = {
                    role_id: this.$store.state.roleId,
                    token: this.$store.state.token,
                    reward_id : this.gameCoinExchangeList[this.choiceCoinCount].id
                }
                this.$api.exchange.exchangeGameCoin(requestData).then(res=> {
                    if(res.data.code == 200) {
                        this.$Toast({
                        	message: '兑换成功',
                        })
                        setTimeout(()=> {
                            this.getExchangeGameCoinList()
                        },1500)
                    }
                })
            },
            //选择兑换列表中的兑换数量
            choiceCoinExchangeCount(i) {
                if(i === this.choiceCoinCount) {
                    return
                }
                this.choiceCoinCount = i
            },
            //点击返回
            backIndex() {
                this.$router.replace({
                    name:'IndexRedPacket'
                })
            },
            //点击详情
            chackDetail() {
                this.$router.push({
                    name:'ExchangeDetail'
                })
            },
            //点击绑定手机号获取验证码
            getPhoneCode(formData) {
                if(!this.$common.verifyPhone(formData.phone)){
                    return
                }
                let requestData = {
                    user_id:this.$store.state.userId,
                    token: this.$store.state.token,
                    code_type:'sdk_bind_phone',
                    phone:formData.phone
                }
                
                this.$api.user.getBindPhoneCode(requestData).then(res=> {
                    if(res.data.code == 200) {
                        this.countdown = 60
                        this.countdownTimer = setInterval(()=> {
                            this.countdown = this.countdown - 1
                            if(this.countdown < 1) {
                                clearInterval(this.countdownTimer)
                                this.countdownTimer = null
                            }
                        },1000)
                        this.$Toast({
                        	message: '验证码已发送',
                        })
                    }else {
                        this.$Toast({
                        	message: res.data.msg,
                        })
                    }
                   
                })
            },
            //绑定手机
            bindPhone(formData) {
                console.log(formData)
                if(!this.$common.verifyPhone(formData.phone)||
                !this.$common.verifyPhoneCode(formData.code)){
                    return
                }
               let requestData = {
                       user_id:this.$store.state.userId,
                       token:this.$store.state.token,
                       phone_code:formData.code,
                       phone:formData.phone
                   }
                this.$api.user.bindPhone(requestData).then(res=> {
                    if (res.data.code === 200) {
                        this.$Toast({
                        	message: '绑定成功',
                        })
                        this.$common.afterBindPhone(formData.phone)
                        this.closeBindPhoneDialog()
                    }
                })
            },
            //关闭绑定手机号弹窗
            closeBindPhoneDialog() {
                this.isShowBindPhoneDialog = false
                // clearInterval(this.countdownTimer)
                // this.countdownTimer = null
                // this.countdown = 0
            }
        }
    }
</script>

<style>
</style>